<h1 mat-dialog-title>{{data.title}}</h1>
<hr>

<mat-dialog-content>
    
    <div class="col-md-6 col-sm-10 form-group">
        <input class="form-control rounded-0" [(ngModel)]="filtro" (keyup)="filterTable($event)" appearance="legacy" placeholder="Buscar" >
      </div>
    <p-table [styleClass]="'p-datatable-xs'" [value]="data.dataSource" [tableStyle]="{'min-width': '50rem','max-height':'17rem','overflow-y':'none'}"
        class="table-striped table-bordered table table-sm border" >
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th class="p-0 header-list-selector-bg"><mat-checkbox [disabled]="!data.multiple" color="primary" [(ngModel)]="selectAll" (change)="selectAllRows()" ></mat-checkbox></th>
                <th class="header-list-selector-bg" *ngFor="let i of data.columns" > {{i?.label}} </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row let-columns="columns">
            <tr [ngClass]="{'selected': row.selected}" class="p-0" *ngIf="filtarFilas(row)"  >
                <td class="p-0" id="col-checkbox-selector" (click)="selectRow(row)">
                    <mat-checkbox class="p-0 m-0" color="primary" [(ngModel)]="row.selected" (change)="selectRow(row)" ></mat-checkbox>
                </td>
                <td class="p-0" *ngFor="let i of data.columns" (click)="selectRow(row)" (dblclick)="selectAndAcept(row)" >
                    {{row[i?.key]}}
                </td>
            </tr>
        </ng-template>
    </p-table>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" class="m-1" (click)="acept()" >
      <i class="fa fa-save"></i> Aceptar </button>
    <button mat-raised-button class="m-1" (click)="cancel()" > 
      <i class="fa fa-remove fa-solid"></i> Cancelar </button>
</mat-dialog-actions>
