<app-header id="main-header"></app-header>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "large" color = "#fff" type = "ball-scale-multiple" [fullScreen] = "true"><p style="color: white" > Procesando </p></ngx-spinner>

<app-menu-sidebar id="main-sidebar" class="sidebar-dark-primary"></app-menu-sidebar>

<div class="content-wrapper">
    <router-outlet></router-outlet>
</div>
<!--<app-footer></app-footer>
<app-control-sidebar></app-control-sidebar>
<div id="sidebar-overlay" (click)="onToggleMenuSidebar()"></div>-->
