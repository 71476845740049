<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark">Dashboadr</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Dashboard v1</li>
                </ol>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <!-- Small boxes (Stat box) -->
        <div class="row">
            <div class="col-lg-3 col-6" *ngIf="sesion.tipo+''=='0'" >
                <div class="small-box bg-info">
                    <div class="inner">
                        <h3>{{cumpleaniosAlumnos.length}}</h3>
                        <p>Alumnos cumplen a&ntilde;os</p>
                    </div>
                    <div class="icon">
                        <i class="fa-solid fa-cake-candles"></i>
                    </div>
                    <a href="#" class="small-box-footer"
                        >More info <i class="fas fa-arrow-circle-right"></i
                    ></a>
                </div>
            </div>
            <div class="col-lg-3 col-6" *ngIf="sesion.tipo+''=='0'">
                <div class="small-box bg-info">
                    <div class="inner">
                        <h3>{{cumpleaniosColaboradores.length}}</h3>
                        <p>Colaboradores cumplen a&ntilde;os</p>
                    </div>
                    <div class="icon">
                        <i class="fa-solid fa-cake-candles"></i>
                    </div>
                    <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-6" *ngIf="sesion.tipo+''=='0'">
                <!-- small box -->
                <div class="small-box bg-success">
                    <div class="inner">
                        <h3>{{colegiaturasPagadas.length}}<sup style="font-size: 20px"></sup></h3>
                        <p> Colegiaturas Pagadas</p>
                    </div>
                    <div class="icon">
                        <i class="fa-solid fa-arrow-trend-up"></i>
                    </div>
                    <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-6" *ngIf="sesion.tipo+''=='0'">
                <!-- small box -->
                <div class="small-box bg-danger">
                    <div class="inner">
                        <h3>{{colegiaturasVencidas.length}}</h3>
                        <p>Colegiaturas vencidas</p>
                    </div>
                    <div class="icon">
                        <i class="fa-solid fa-arrow-trend-down"></i>
                    </div>
                    <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-6" *ngIf="sesion.tipo+''=='0'">
                <!-- small box -->
                <div class="small-box bg-warning">
                    <div class="inner">
                        <h3>44</h3>
                        <p>User Registrations</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-person-add"></i>
                    </div>
                    <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="sesion.tipo+''=='0'" >
            <div class="col-lg-1"></div>
            <div class="col-lg-10 col-6">
                <canvas #chartCanvas></canvas>
            </div>
            <div class="col-lg-1"></div>
        </div>
        <!-- /.row -->
    </div>
    <!-- Main row -->
</section>
<!-- /.content -->


