import { EspecialidadModel } from '@/store/catalogos/especialidad.model';
import { NivelModel } from '@/store/catalogos/nivel.model';
import { ProductoModel } from '@/store/catalogos/producto.model';
import { ProductoEspecialidadModel } from '@/store/catalogos/productoEspecialidad';
import { properties } from '@/utils/properties';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EspecialidadService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<EspecialidadModel[]> {
    return this.http.get<EspecialidadModel[]>(properties.URL_CATALOGS + 'especialidad', properties.httpOptions);
  }

  getNivelesEspecialidad(p:NivelModel): Observable<NivelModel[]> {
    return this.http.post<NivelModel[]>(properties.URL_CATALOGS + 'nivel/especialidad',p, properties.httpOptions);
  }

  getProductosEspecialidad(p:EspecialidadModel): Observable<NivelModel[]> {
    return this.http.post<NivelModel[]>(properties.URL_CATALOGS + 'productos/especialidad',p, properties.httpOptions);
  }

  new(p:EspecialidadModel): Observable<EspecialidadModel> {
    return this.http.post<EspecialidadModel>(properties.URL_CATALOGS + 'especialidad', p, properties.httpOptions);
  }

  delete(id:number): Observable<EspecialidadModel> {
    return this.http.delete<EspecialidadModel>(properties.URL_CATALOGS + 'especialidad/'+id, properties.httpOptions);
  }

  update(p: EspecialidadModel): Observable<EspecialidadModel> {
    return this.http.put<EspecialidadModel>(properties.URL_CATALOGS + 'especialidad/'+p.id_especialidad,p, properties.httpOptions);
  }

  updateStatus(p: EspecialidadModel): Observable<EspecialidadModel> {
    return this.http.put<EspecialidadModel>(properties.URL_CATALOGS + 'especialidad/status/'+p.id_especialidad,p, properties.httpOptions);
  }
   
   
}
