import { AlumnoModel } from '@/store/catalogos/alumno.model';
import { PagoDetalleModel } from '@/store/catalogos/pagoDetalle.model';
import { PagoEncabezadoModel } from '@/store/catalogos/pagoEncabezado.model';
import { properties } from '@/utils/properties';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PagoService {

  constructor(private http: HttpClient) { }

  getAdeudosAlumno(p:AlumnoModel): Observable<any> {
    return this.http.post<any>(properties.URL_CATALOGS + 'pago/adeudosalumno',p, properties.httpOptions);
  }

  new(p:any): Observable<any> {
    console.log(p);
    return this.http.post<any>(properties.URL_CATALOGS + 'pago', p, properties.httpOptions);
  }

  getTicket(p:PagoEncabezadoModel): Observable<any> {
    return this.http.post<any>(properties.URL_CATALOGS + 'pago/ticket',p, properties.httpOptions);
  }

  getAll(): Observable<any> {
    return this.http.get<any>(properties.URL_CATALOGS + 'pago', properties.httpOptions);
  }

  getPagosAtrasadosYProximos(): Observable<any> {
    return this.http.post<any>(properties.URL_CATALOGS + 'pago/atrasados-proximos',{}, properties.httpOptions);
  }

  cancelarPago(p:PagoEncabezadoModel): Observable<any> {
    return this.http.post<any>(properties.URL_CATALOGS + 'pago/cancelarPago',p, properties.httpOptions);
  }

  reenviarTicket(p): Observable<any> {
    return this.http.post<any>(properties.URL_CATALOGS + 'pago/ticket/reenviar',p, properties.httpOptions);
  }

}
