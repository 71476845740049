import { AlumnoModel } from '@/store/catalogos/alumno.model';
import { TutorModel } from '@/store/catalogos/tutor.model';
import { properties } from '@/utils/properties';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlumnoService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<AlumnoModel[]> {
    return this.http.get<AlumnoModel[]>(properties.URL_CATALOGS + 'alumno', properties.httpOptions);
  }

  getPorSucursal(p:any): Observable<AlumnoModel[]> {
    return this.http.post<AlumnoModel[]>(properties.URL_CATALOGS + 'alumno/r/sucursal',p, properties.httpOptions);
  }

  getPorHorario(p:any): Observable<AlumnoModel[]> {
    return this.http.post<AlumnoModel[]>(properties.URL_CATALOGS + 'alumno/r/grupo',p, properties.httpOptions);
  }

  getTutores(p:AlumnoModel): Observable<TutorModel[]> {
    return this.http.post<TutorModel[]>(properties.URL_CATALOGS + 'tutor/alumno',p, properties.httpOptions);
  }

  new(p:AlumnoModel): Observable<AlumnoModel> {
    return this.http.post<AlumnoModel>(properties.URL_CATALOGS + 'alumno', p, properties.httpOptions);
  }

  delete(id:number): Observable<AlumnoModel> {
    return this.http.delete<AlumnoModel>(properties.URL_CATALOGS + 'alumno/'+id, properties.httpOptions);
  }

  update(p: AlumnoModel): Observable<AlumnoModel> {
    return this.http.put<AlumnoModel>(properties.URL_CATALOGS + 'alumno/'+p.id_alumno,p, properties.httpOptions);
  }

  updateStatus(p: AlumnoModel): Observable<AlumnoModel> {
    return this.http.put<AlumnoModel>(properties.URL_CATALOGS + 'alumno/status/'+p.id_alumno,p, properties.httpOptions);
  }
   
   
}
