import {Component, OnInit} from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private primengConfig: PrimeNGConfig) {
    
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
    window.location.hash="no-back-button";
    window.location.hash="Again-No-back-button" //chrome
    window.onhashchange=function(){
      window.location.hash="no-back-button";
    }
    window.onbeforeunload = function() {
      return alert("¿Estás seguro que deseas salir de la actual página?");
    }
    
  }

}
