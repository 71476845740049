import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import { ApiService } from './api.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public user: any = null;

    constructor(private router: Router, private toastr: ToastrService, private apiService:ApiService
        , private spinner : NgxSpinnerService) {}

    async loginByAuth({email, password}) {
        try {
            let p = {email:email,password:password};
            //sessionStorage.setItem("token","TOKEN");
            //this.router.navigate(['/']);
            this.spinner.show();
            this.apiService.login(p).subscribe(response=>{
                this.spinner.hide();
                if(response){
                    console.log(response);
                    //this.loadMenu();
                    //this.getProfile();
                    this.router.navigate(['/']);
                    this.toastr.success('Login success');
                }else{
                    this.toastr.error("Credenciales invalidas");
                }
            }, error=>{
                this.spinner.hide();
                this.toastr.error("Credenciales invalidas");
            });
        } catch (error) {
            this.toastr.error("Credenciales invalidas");
        }
    }

    async loadMenu(){        
        try {
            this.apiService.menu().subscribe(response=>{
                localStorage.setItem("menu",response.toString());
            }, error=>{
                this.toastr.error("Error al cargar menu");
            });
        } catch (error) {
            this.toastr.error("Error al cargar menu");
        }
    }

    async registerByAuth({email, password}) {
        try {
            const token ="";
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
            this.toastr.success('Register success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    getMenu(){
        return JSON.parse(localStorage.getItem("menu"));
    }

    async getProfile() {
        try {
            this.user = {
              "ID": "7d0942e6-a274-4b5d-9ef6-568f9d39cba8",
              "email": "admin@example.com",
              "username": "admin",
              "provider": "AUTH",
              "metadata": {},
              "isVerified": false,
              "createdAt": "2021-05-14T12:12:00.976Z",
              "updatedAt": "2021-05-14T12:12:00.976Z",
              "picture":""
          };
        } catch (error) {
            this.logout();
            throw error;
        }
    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('menu');
        sessionStorage.removeItem('sucursalCambiada');
        this.user = null;
        this.router.navigate(['/login']);
    }






}
