<!-- Brand Logo -->
<a href="javascript:void(0)" class="brand-link">
    <div class="row">
        <div class="col-10">
            <pf-image src="assets/img/logo.png" alt="IFAM LOGO" class="brand-image"
                height="33" width="33" rounded style="opacity: 0.8" ></pf-image>
            <span class="brand-text font-weight-light">IFAM</span>
        </div>
        <div class="col-2 brand-text p-0 pr-3">
            <button (click)="ocultarMenu()" type="button" class="btn btn-xs btn-outline-info btn-inf btn-flat pl-1 pt-0 pb-0 pr-1">
                <span class="fa fa-times m-1"></span>
            </button>
        </div>
    </div>
</a>

<!-- Sidebar -->
<div class="sidebar">
    <!-- Sidebar user (optional) -->
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
            <pf-image [src]="user.picture" fallback-src="assets/img/default-profile.png"
                class="img-circle" alt="User Image" width="34" height="34" rounded ></pf-image>
        </div>
        <div class="info">
            <a [routerLink]="['/profile']" class="d-block"> {{ sesion.numero_colaborador}} 
                <br/> {{sesion.nombre+" "+sesion.apellido_paterno }} 
                <br/> {{sesion?.sucursal?.descripcion }} </a>
        </div>
    </div>

    <div class="form-inline">
        <app-sidebar-search></app-sidebar-search>
    </div>

    <!-- Sidebar Menu -->
    <nav class="mt-2" style="overflow-y: hidden">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false" >
            <li class="nav-item small" >
                <a  href="javascript:void(0)"
                    [routerLinkActive]="'active'"
                    class="nav-link" >&nbsp;&nbsp;
                    <p class="text-info">
                        <mat-slide-toggle (change)="changeOcultarMenu()" [(ngModel)]="autoOcultarMenu" color="primary" >Auto-ocultar Menu</mat-slide-toggle>
                    </p>
                </a>
            </li>
            <li class="nav-item" *ngIf="sesion.tipo==0" >
                <a  href="javascript:void(0)"
                    [routerLinkActive]="'active'"
                    class="nav-link"
                    (click)="mostrarSelectorSucursal()" >&nbsp;&nbsp;
                    <p class="">
                        Cambiar Sucursal
                    </p>
                </a>
            </li>
            <app-menu-item *ngFor="let item of menu" [menuItem]="item" ></app-menu-item>
            
        </ul>
    </nav>
</div>
