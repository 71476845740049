import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { properties } from '@/utils/properties';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(private http: HttpClient) {}

    login(creds:any){
        console.log(creds);
        return this.http.post(properties.URL_LOGIN, creds, {
            observe: 'response'
        }).pipe(map((response:HttpResponse<any>) => {
            const body = response.body;
            if(body.success){
                console.log(body);
                const headers = response.headers;
                const bearerToken = "EAAEsLlj6o4YBOZCJCLwnFz3wYHg1dWo68Y9CWxEUhIHbNcJZBWgNoZA5b2dZAONUoTH4EwoVZBb84DXLDhoZAVI1kB5PRlAZBHFHz7UadKYFLc8zIEnlJZC9mPjdU6DHVpCpDPwWaQBzcriT7Q4kzfEqr16hX0ZAWOgf8QUZB7GXNRggvU5mydaW8gnwNTM102n6GOJQZBaKW3wD4twpccV4ydROlRkoWYZD";// headers.get("Authorization");
                const token = bearerToken.replace("Bearer ","");
                sessionStorage.setItem("token",token);
                sessionStorage.setItem("colaborador",JSON.stringify(body.data));
                return body;
            }
            return null;
        }));
    }

    menu():Observable<any>{
        return this.http.post(properties.URL_ADMIN+"privilege",{}, properties.httpOptions);
    }

    privilegesNodes():Observable<any>{
        return this.http.post(properties.URL_ADMIN+"privilege/all",{}, properties.httpOptions);
    }

    modules():Observable<any>{
        return this.http.get(properties.URL_ADMIN+"module", properties.httpOptions);
    }

    getSesion(){
        let token = sessionStorage.getItem("token");
        let sesion = null;
        if(token!=undefined && token!=null && token!=""){
            sesion = JSON.parse(atob(token.split('.')[1])) ;
        }
        return sesion;
    }

    getToken(){
        //this.getSesion();
        let token = sessionStorage.getItem("token");
        return token;
    }
    
    private getFiles() {
        return Promise.resolve(this.getTreeNodesData());
    }

    private getTreeNodesData() {
        return [{
                key: '0',
                label: 'Documents',
                data: {attr1:"aaaaaa",attr2:"aaaaaa",attr3:"aaaaaa",attr4:{subattr1:"11111",subattr2:"22222",subattr3:"33333",subattr4:"44444"}},
                icon: 'pi pi-fw pi-inbox',
                children: [{
                        key: '0-0',
                        label: 'Work',
                        data: {attr1:"aaaaaa",attr2:"aaaaaa",attr3:"aaaaaa",attr4:{subattr1:"11111",subattr2:"22222",subattr3:"33333",subattr4:"44444"}},
                        icon: 'pi pi-fw pi-cog',
                        children: [
                            { key: '0-0-0', label: 'Expenses.doc', icon: 'pi pi-fw pi-file', data: 'Expenses Document' },
                            { key: '0-0-1', label: 'Resume.doc', icon: 'pi pi-fw pi-file', data: 'Resume Document' }
                        ]
                    },{
                        key: '0-1',
                        label: 'Home',
                        data: 'Home Folder',
                        icon: 'pi pi-fw pi-home',
                        children: [{ key: '0-1-0', label: 'Invoices.txt', icon: 'pi pi-fw pi-file', data: 'Invoices for this month' }]
                    }
                ]
            }
        ];
    }

}
