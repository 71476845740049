import { ProductoModel } from '@/store/catalogos/producto.model';
import { properties } from '@/utils/properties';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductoService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<ProductoModel[]> {
    return this.http.get<ProductoModel[]>(properties.URL_CATALOGS + 'producto', properties.httpOptions);
  }
  
  getActivos(): Observable<ProductoModel[]> {
    return this.http.get<ProductoModel[]>(properties.URL_CATALOGS + 'producto/r/activos', properties.httpOptions);
  }

  new(p:ProductoModel): Observable<ProductoModel> {
    return this.http.post<ProductoModel>(properties.URL_CATALOGS + 'producto', p, properties.httpOptions);
  }

  delete(id:number): Observable<ProductoModel> {
    return this.http.delete<ProductoModel>(properties.URL_CATALOGS + 'producto/'+id, properties.httpOptions);
  }
   
  update(p: ProductoModel): Observable<ProductoModel> {
    return this.http.put<ProductoModel>(properties.URL_CATALOGS + 'producto/'+p.id_producto,p, properties.httpOptions);
  }

  updateStatus(p: ProductoModel): Observable<ProductoModel> {
    return this.http.put<ProductoModel>(properties.URL_CATALOGS + 'producto/status/'+p.id_producto,p, properties.httpOptions);
  }
}
