import { ParametroModel } from '@/store/catalogos/parametro.model';
import { properties } from '@/utils/properties';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParametroService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<ParametroModel[]> {
    return this.http.get<ParametroModel[]>(properties.URL_CATALOGS + 'parametro', properties.httpOptions);
  }

  new(p:ParametroModel): Observable<ParametroModel> {
    return this.http.post<ParametroModel>(properties.URL_CATALOGS + 'parametro', p, properties.httpOptions);
  }

  dashboard(p:ParametroModel): Observable<any> {
    return this.http.post<ParametroModel>(properties.URL_CATALOGS + 'parametro/dashboard', p, properties.httpOptions);
  }

  delete(id:number): Observable<ParametroModel> {
    return this.http.delete<ParametroModel>(properties.URL_CATALOGS + 'parametro/'+id, properties.httpOptions);
  }
   
  update(p: ParametroModel): Observable<ParametroModel> {
    return this.http.put<ParametroModel>(properties.URL_CATALOGS + 'parametro/'+p.id_parametro,p, properties.httpOptions);
  }

  updateStatus(p: ParametroModel): Observable<ParametroModel> {
    return this.http.put<ParametroModel>(properties.URL_CATALOGS + 'parametro/status/'+p.id_parametro,p, properties.httpOptions);
  }
}
