import { SucursalModel } from '@/store/catalogos/sucursal.model';
import { properties } from '@/utils/properties';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SucursalService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<SucursalModel[]> {
    return this.http.get<SucursalModel[]>(properties.URL_CATALOGS + 'sucursal', properties.httpOptions);
  }

  getActivas(): Observable<SucursalModel[]> {
    return this.http.get<SucursalModel[]>(properties.URL_CATALOGS + 'sucursal/r/activas', properties.httpOptions);
  }

  new(p:SucursalModel): Observable<SucursalModel> {
    return this.http.post<SucursalModel>(properties.URL_CATALOGS + 'sucursal', p, properties.httpOptions);
  }

  delete(id:number): Observable<SucursalModel> {
    return this.http.delete<SucursalModel>(properties.URL_CATALOGS + 'sucursal/'+id, properties.httpOptions);
  }
   
  update(p: SucursalModel): Observable<SucursalModel> {
    return this.http.put<SucursalModel>(properties.URL_CATALOGS + 'sucursal/'+p.id_sucursal,p, properties.httpOptions);
  }

  updateStatus(p: SucursalModel): Observable<SucursalModel> {
    return this.http.put<SucursalModel>(properties.URL_CATALOGS + 'sucursal/status/'+p.id_sucursal,p, properties.httpOptions);
  }
}
