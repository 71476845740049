import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import { ApiService } from '@services/api.service';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import Chart, { ChartItem } from 'chart.js/auto';
import { getRelativePosition } from 'chart.js/helpers';
import { ParametroService } from '@services/catalogos/parametro.service';
import { ColaboradorModel } from '@/store/catalogos/colaborador.model';
import { Util } from '@/utils/util';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  @ViewChild('chartCanvas', { static: true }) chartCanvas!: ElementRef<HTMLCanvasElement>;

  colegiaturasPagadas=[];
  colegiaturasVencidas=[];
  cumpleaniosAlumnos=[];
  cumpleaniosColaboradores=[];

  sesion:ColaboradorModel;
  
  constructor(private renderer: Renderer2,private toastr: ToastrService, private apiService: ApiService
    ,private parametroService:ParametroService,private util: Util
  ) {
    this.sesion = this.util.getSesion();

  }

  ngOnInit() {
  }
    
  ngAfterViewInit(): void {
    const canvasElement: HTMLCanvasElement = this.chartCanvas.nativeElement;
    const chartItem: ChartItem = canvasElement as ChartItem;
    
    
    let p = {};
    this.parametroService.dashboard(p).subscribe(r=>{
      console.log("r ",r);
      
      this.colegiaturasPagadas=r?.data?.colegiaturas_pagadas;
      this.colegiaturasVencidas=r?.data?.colegiaturas_vencidas;
      this.cumpleaniosAlumnos=r?.data?.cumpleanios_alumnos;
      this.cumpleaniosColaboradores=r?.data?.cumpleanios_colaboradores;
      
      let min=0;
      let max=0;
      let dias=[];
      let adeudos=[];
      let reecaudado=[];

      let indicadoresGrafica = r?.data?.indicadores_grafica;
      indicadoresGrafica.forEach(element => {
        dias.push(element.dia_semana);
        adeudos.push(element.vencido);
        reecaudado.push(element.recaudado);

        if(max<element.recaudado){
          max=element.recaudado;
        }
        if(max<element.vencido){
          max=element.vencido;
        }
        
      });

      const labels = dias ; // ['Lunes','Martes','Miercoles','Jueves','Viernes','Sabado','Domingo'];
      const data = {
        labels: labels,
        datasets: [
          {
            label: 'Adeudos',
            data: adeudos,//[200, 500, 150, 600, 200, 900, 0],
            borderColor:'lightgray',
            backgroundColor: 'purple',
          },
          {
            label: 'Recaudado',
            data: reecaudado,//[800, 0, 50, 500, 200, 0, 90],
            borderColor: 'lightgray',
            backgroundColor: 'blue',
          }
        ]
      };
      new Chart(chartItem, {
        type: 'line',
        data: data,
        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: 'Ingresos por colegiaturas'
            }
          },
          scales: {
            y: {
              min: min,//0,
              max: max,//1000,
            }
          }
        },
      });
    }, err=>{
      console.log("err", err);
    });
  }

}
