import {AfterViewInit, Directive, ElementRef, HostListener, Input, OnInit, Renderer2, Optional, OnDestroy} from '@angular/core';
import {NgModel} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Directive({
    selector: 'input[maxLength]'
})
export class MaxLengthDirective implements OnInit {
    @Input() maxLength: number;
    private destroyed$ = new Subject();
  
    constructor(
      private el: ElementRef,
      private renderer: Renderer2,
      @Optional() private ngModel: NgModel,
    ) {}
  
    ngOnInit() {
      this.renderer.setAttribute(this.el.nativeElement, 'maxLength', this.maxLength.toString());
      if (this.ngModel) {
        this.ngModel.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
        })
      }
    }

}