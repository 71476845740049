import { ColaboradorModel } from '@/store/catalogos/colaborador.model';
import { SucursalModel } from '@/store/catalogos/sucursal.model';
import { Util } from '@/utils/util';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EspecialidadService } from '@services/catalogos/especialidad.service';
import { SucursalService } from '@services/catalogos/sucursal.service';
import { ValidatorsService } from '@services/util/validators.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-adm-seleccionar-sucursal',
  templateUrl: './adm-seleccionar-sucursal.component.html',
  styleUrls: ['./adm-seleccionar-sucursal.component.scss']
})
export class AdmSeleccionarSucursalComponent {

  listaSucursales:SucursalModel[] = [];

  sucursalSeleccionada:SucursalModel;

  constructor(
      private sucursalService: SucursalService
    , private especialidadService: EspecialidadService
    , private util: Util
    , private validatorsService: ValidatorsService
    , public dialogRef: MatDialogRef<AdmSeleccionarSucursalComponent>
    , public dialog: MatDialog
    , private spinner: NgxSpinnerService
    , @Inject(MAT_DIALOG_DATA) public data: any ){
      console.log("MODAL SELECCIONAR SUCURSAL",data);
      this.sucursalSeleccionada = this.data.row.sucursal;
      this.cargarSucursales();
  }

  cargarSucursales(){
    this.sucursalService.getAll().subscribe(data=>{
      this.listaSucursales = data;
      this.listaSucursales.forEach(sucursal=>{
        if(sucursal.id_sucursal==this.data.row.sucursal.id_sucursal){
          this.sucursalSeleccionada = sucursal;
        }
      });
    });
  }

  seleccionarSucursal(){
    if(this.util.isEmpty(this.sucursalSeleccionada)){
      this.util.errorMessage("Selecciona una sucursal");
      return;
    }
    this.dialogRef.close({ data: this.sucursalSeleccionada ,success:true});
  }

}
