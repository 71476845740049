import { FormGroup } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Injectable, Renderer2 } from '@angular/core';

import swal from 'sweetalert2';
import { CalendarioPagoModel } from "@/store/catalogos/calendarioPago.model";
import { DatePipe } from "@angular/common";
import { ColaboradorModel } from "@/store/catalogos/colaborador.model";

@Injectable({
  providedIn: 'root'
})
export class Util {

  constructor(
     private toastr: ToastrService
    ,private datePipe:DatePipe) {
  }

  public static validateReactiveField(campo: string, form: FormGroup) {
    return form.controls[campo].errors && form.controls[campo].touched;
  }

  public errorMessage(message: string) {
    this.toastr.error(message);
  }

  public successMessage(message: string) {
    this.toastr.success(message);
  }

  public puntearTexto(texto, longitud){
    return texto.length>=longitud;
    return texto;
  }

  public lpad(num:number, size:number): string {
    let s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }

  public confirmMessage(message: string, do_) {
    swal.fire({
      title: message,
      showDenyButton: true,
      //showCancelButton: true,
      icon:'info',
      confirmButtonText: 'Si',
      denyButtonText: 'No',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
        //denyButton: 'order-3',
      }
    }).then((result) => {
      if (result.isConfirmed) {
        do_();
        //swal.fire('Saved!', '', 'success')
      } //else if (result.isDenied) {
      //swal.fire('Changes are not saved', '', 'info')
      //}
    })
  }

  public infoMessage(title,message: string, do_) {
    swal.fire({
      title: title,
      html:message,
      //showDenyButton: true,
      //showCancelButton: true,
      confirmButtonText: 'Entendido',
      icon:'info',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
        //denyButton: 'order-3',
      }
    }).then((result) => {
      if (result.isConfirmed) {
        do_();
        //swal.fire('Saved!', '', 'success')
      } //else if (result.isDenied) {
      //swal.fire('Changes are not saved', '', 'info')
      //}
    })
  }
   
  public autoOcultarMenu(renderer: Renderer2){
    const autoOcultarMenu = JSON.parse(localStorage.getItem("autoOcultarMenu"));
    if(autoOcultarMenu){
        //renderer.removeClass(document.querySelector('app-root'),'sidebar-open');
        //renderer.addClass(document.querySelector('app-root'),'sidebar-collapse');
        this.ocultarMenu(renderer);
    }
  }
   
  public ocultarMenu(renderer: Renderer2){
      renderer.removeClass(document.querySelector('app-root'),'sidebar-open');
      renderer.addClass(document.querySelector('app-root'),'sidebar-collapse');
  }

  public isEmpty(val) {
    return val == undefined || val == null || val == "";
  }

  public isTrue(val){
    return val==true || val==1 || val=='1';
  }

  public nvl(val1, val2):any {
    return this.isEmpty(val1) ? val2 : val1;
  }

  public calcularIva(importe:number):any {
    return (importe*1.16)-importe;
  }

  public validaFecha(fecha: string): boolean {
    return fecha.match("[0-9]{2}[/]{1}[0-9]{2}[/]{1}[0-9]{4}") != null;
  }
  // calcula la edad.
  public calculateAge(fechaNacimiento: Date): number {
    var timeDiff = Math.abs(Date.now() - fechaNacimiento.getTime());
    let age = Math.ceil((timeDiff / (1000 * 3600 * 24)) / 365);
    if(isNaN(age)){
      return null;
    }
    return age;
  }

  public static getNumbers(p: string) {
    return p?.replace(/[^0-9]+/g, "");
  }

  // cuenta los días habiles, no cuenta los 5 sabados y 6 domingos
  public workingDays(startingDate: Date, endDate: Date): number {
    let workingDays = 0;
    const day = 24 * 60 * 60 * 1000;
    while (startingDate <= endDate) {
      const week = startingDate.getDay();
      if (week !== 5 && week !== 6) {
        workingDays++;
      }
      startingDate.setTime(startingDate.getTime() + day);
    }
    return workingDays;
  }

  isValiField(field: string, form: FormGroup): boolean | null {
    return form.controls[field].errors
      && form.controls[field].touched;
  }

  getFieldError(field: string, form: FormGroup): string | null {
    if (!form.controls[field]) return null;
    const errors = form.controls[field].errors || {};

    for (const key of Object.keys(errors)) {
      switch (key) {
        case 'required':
          return 'Este campo es requerido';
        case 'minlength':
          return `Mínimo ${errors['minlength'].requiredLength} caracters.`;
      }

    }
  }

  toNumber(p:any):Number|null|undefined{
    return Number(p);
  }

  stringify(data){
    return JSON.stringify(data);
  }

  jsonParse(data){
    return JSON.parse(data);
  }

  isEmail(email: string):boolean {
    let isValid = false;
      'use strict';
      var REGEX = /^[a-zA-Z0-9.!#$%&’+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/;
      if (email.match(REGEX)){
        isValid = true;
      }
    return isValid;
  }

  isRfc(rfc: string):boolean {
    let isValid = false;
      'use strict';
      var REGEX = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
      if (rfc.match(REGEX)){
        isValid = true;
      }
    return isValid;
  }

  normalize(str:string|undefined) {
    return this.nvl(str,"").normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  private calcularCalendarioPagos(_duracionEspecialidad ,_costoEspecialidad
                         ,_fechaInicioClases    ,_fecha_inicio_grupo
                         ,tipoPago:string):CalendarioPagoModel[]{
    let TIMEZONE = "T09:00:00.000+06:00";
    let listaPagos:CalendarioPagoModel[] = [];
    let costo = 0;
    let noMesesPago:number = 0;

    switch(tipoPago){
      case 'CONTADO':
        noMesesPago = _duracionEspecialidad;
        break;
      case 'SEMESTRE':
        noMesesPago = 6;
        break;
      case 'TRIMESTRE':
        noMesesPago = 3;
        break;
      case 'DIFERIDO':
        noMesesPago = 1;
        break;
      default:
        noMesesPago = 1;
        break;            
    }

    let fecha_inicio_alumno = new Date(_fechaInicioClases+TIMEZONE);
    //let fecha_inicio_grupo = new Date(_fecha_inicio_grupo);
    //let totPagos = Math.trunc(_duracionEspecialidad / noMesesPago);
    let diaDelMesPago = fecha_inicio_alumno.getUTCDate();
    let pagoPorMes = _costoEspecialidad / _duracionEspecialidad;
    //let totalPagoParcialidad = _costoEspecialidad / totPagos;

    let mesesRestantes = _duracionEspecialidad;
    let cantidadPago = pagoPorMes*noMesesPago;
    let noPago = 1;
    let fechaPago = fecha_inicio_alumno;
    //INGRESAR DIA DEL MES DE PAGO
    fechaPago.setDate(diaDelMesPago);
    //AGREGAR PAGO A LA LISTA
    let pagoNuevo = {
      no_pago    : noPago,
      fecha_pago : this.datePipe.transform(fechaPago,'Y-MM-dd'),
      cantidad   : cantidadPago
    };

    listaPagos.push(pagoNuevo);
    //restar los meses del pago 
    
    costo+=cantidadPago;

    for(let i = noMesesPago; i<_duracionEspecialidad ; i+=noMesesPago){

      noPago++;
      let pagoAnterior=Object.assign({},pagoNuevo);
      //CALCULAR TOTAL DE PAGO PERIODO
      mesesRestantes=mesesRestantes-noMesesPago;
      cantidadPago = pagoPorMes*noMesesPago;

      let nuevoMes = 0;
      if(mesesRestantes<noMesesPago){
        nuevoMes = (fechaPago.getMonth() + mesesRestantes);
      }else{
        nuevoMes = (fechaPago.getMonth() + noMesesPago);
      }
      
      let fechaPagoAuxiliar = new Date(pagoAnterior.fecha_pago+TIMEZONE);
      fechaPagoAuxiliar.setMonth(nuevoMes-1);
      let numDiasMes = new Date(fechaPagoAuxiliar.getFullYear(), fechaPagoAuxiliar.getMonth(), 0).getDate();

      let _diaDelMesPago = 0;
      if(numDiasMes<diaDelMesPago){
        _diaDelMesPago = numDiasMes;
      }else{
        _diaDelMesPago = diaDelMesPago;
      }
      
      if(mesesRestantes<noMesesPago){
        cantidadPago = pagoPorMes * mesesRestantes;
      }
      let fecha = fechaPagoAuxiliar.getFullYear()+"-"+(nuevoMes+"".length==1?"0"+nuevoMes:nuevoMes)+"-"+(diaDelMesPago)+TIMEZONE;
      fechaPago = new Date(fecha);
      
      pagoNuevo = {
        no_pago    : noPago,
        fecha_pago : this.datePipe.transform(fechaPago,'Y-MM-dd'),
        cantidad   : cantidadPago
      };
      listaPagos.push(pagoNuevo);
      costo+=cantidadPago;

    }
    return listaPagos;
  }
  
  getSesion():ColaboradorModel{
    return JSON.parse(sessionStorage.getItem('colaborador'));
  }
  
  setSesion(data:ColaboradorModel){
    sessionStorage.setItem("colaborador",JSON.stringify(data));
  }

  horaSinSegundos(hora){
    if(this.isEmpty(hora)){
      return '';
    }
    return hora.substring(0,5);
  }
  
}
