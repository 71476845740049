import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[uppercase]'
})
export class UppercaseDirective {

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const control = this.ngControl.control;
    const initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue?.toUpperCase();
    control?.setValue(initalValue?.toUpperCase());
    if (initalValue !== this._el.nativeElement.value) {
        event.stopPropagation();
    }


  }
/*
  @Input('akoDisabledIf') set disabledIf(condition: boolean) {
    const control = this.ngControl.control;

    condition ? control?.disable() : control?.enable();
  }*/

  constructor(private ngControl: NgControl,private _el: ElementRef) {}
  
}
