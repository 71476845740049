<h1 mat-dialog-title>Cambiar sucursal</h1>
<hr>
    <mat-dialog-content>
        <div class="row">
            <div class="col-md-12 form-group">
                <p>
                    Tu usuario es Administrador de Sistema, por lo que puedes cambiar la sucursal en la que deseas efectuar operaciones
                    como Inscripcion, cobro o algunas otra en las que se requiersa saber en que sucursal estas
                    <br/> <strong>Este cambio solo es aplicable a esta sesi&oacute;n</strong> 
                </p>
            </div>
            <div class="col-md-12 form-group">
                <label>Sucursal</label>
                <select [(ngModel)]="sucursalSeleccionada" class="form-control form-control-sm rounded-0 shadow-background" >
                    <option *ngFor="let row of listaSucursales" [ngValue]="row"> {{row.descripcion}}
                    </option>
                </select>
            </div>
        </div>


    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-raised-button color="primary" class="m-1" (click)="seleccionarSucursal()" >
            <i class="fa fa-save"></i> Seleccionar sucursal </button>
        <button mat-raised-button color="warn" class="m-1" mat-dialog-close>
            <i class="fa fa-remove fa-solid"></i> Cancelar </button>
    </mat-dialog-actions>
